import React, { Component, useState, useEffect, useMemo, useRef } from "react";
import { Link, Navigate, useNavigate } from "react-router-dom";
import { useTable } from "react-table";
import { Grid, Input, Header, Message, Table, Pagination, Dimmer, Loader, Segment } from 'semantic-ui-react'
import ProductService from "../../../services/product.service";
import EventBus from "../../../common/EventBus";
import AuthService from "../../../services/auth.service";



const Record = (props) => (
    <Table.Row {...props.record.getRowProps()}>
        {props.record.cells.map((cell) => {
                    return (
                      <Table.Cell {...cell.getCellProps()}>{cell.render("Cell")}</Table.Cell>
                    );
                  })}
    </Table.Row>
   );
const pageSizes = [10, 25, 50, 100, 500];

const Products =()=>{
    const [loading, setLoading] = useState("");
    const [records, setRecords] = useState([]);
    const [currentRow, setCurrentRow] = useState(null);
    const [currentIndex, setCurrentIndex] = useState(-1);
    const [searchKeyword, setSearchKeyword] = useState("");
    const [open, setOpen] = useState(false);
    const [activePage, setActivePage] = useState(1);
    const [count, setCount] = useState(0);
    const [pageSize, setPageSize] = useState(10);

    const onChangeSearchKeyword = (e) => {
        const searchKeyword = e.target.value;
        setSearchKeyword(searchKeyword);
        //setActivePage(1);
    }
    const getRequestParams = (searchKeyword,page,pageSize) => {
        let params = {};
            if (searchKeyword) {
                params["keyword"] = searchKeyword;
            }
            if (page) {
                params["page"] = page - 1;
            }
            if (pageSize) {
                params["size"] = pageSize;
            }
        return params;
    };
    const retrieveProducts=()=>{
        setLoading("active");
        const params = getRequestParams(searchKeyword,activePage,pageSize);
        console.log(params);
        ProductService.get(params)
            .then(response=>{
                const { records , totalPages } = response.data.payload;
                records.forEach((record, index) => { record.serial = (index + 1)+((activePage-1)*pageSize); });
                setRecords(records);
                setCount(totalPages)
                console.log(response.data);
                setLoading("");
            },
            error => {
                if (error.response && error.response.status === 401) {
                    EventBus.dispatch("logout");
                  }
                if (error.response && error.response.status === 403) {
                    EventBus.dispatch("logout");
                }
                setLoading("");
              }
            ).catch(e=>{
                console.log(e);
                setLoading("");
            });
    }
   
    
      const handlePageChange = (e, { activePage }) => {
        setActivePage(activePage);
      };
    
      const handlePageSizeChange = (event) => {
        setActivePage(1);
        setPageSize(event.target.value);
      };
      const onKeyPress= (e) => {
        if(e.key === 'Enter')
        retrieveProducts();
       }
       const findKeyword = (event) => {
        retrieveProducts();
        setActivePage(1);
      };

      const refreshList=(event)=>{
        retrieveProducts();
        setCurrentRow = null;
        setCurrentIndex = event.target.index;
    }

    const setActiveRow=(event)=>{
        setCurrentRow = event.target.Record;
        setCurrentIndex = event.target.index;
    }
  
       // This method will delete a record
    const deleteRecord=()=> {
        ProductService.delete(searchKeyword)
        .then(response => {
            console.log(response.data);
            setRecords(response.data.payload.records)
           
        }).catch(e=>{
            console.log(e);
        });
    }

    const columns = useMemo(
        () => [
            {
                Header: '#',
                accessor: 'serial'
            },
          {
            Header: "Keyword",
            accessor: "Keyword",
          },
          {
            Header: "Class",
            accessor: "Class",
          },
          {
            Header: "Code",
            accessor: "Code",
          },
          {
            Header: "Tag",
            accessor: "Tag",
          },
          {
            Header: "Status",
            accessor: "IsActive",
            Cell: (props) => {
              return props.value ? "Active" : "Inactive";
            },
          },
          {
            Header: "Actions",
            accessor: "actions",
            Cell: (props) => {
              const rowIdx = props.row.id;
              return (
                <div>
               
              </div>
              );
            },
          },
        ],
        []
      );
    const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    } = useTable({
    columns,
    data: records,
    });

    const recordList=() =>{
        return rows.map((record, i) => {
            prepareRow(record);
            return (
                <Record
                record={record}
                deleteRecord={() => deleteRecord()}
                key={record._id}
                />
            );
        });
      }
      const navigate = useNavigate();

      useEffect(() => {
        const currentUser = AuthService.getCurrentUser();
        const goToHomePage = () => navigate('/home');
        if(!currentUser) goToHomePage();
        retrieveProducts();
      }, [activePage, pageSize, navigate]);

      return (
        <Segment>
            <Dimmer active={loading} inverted>
            <Loader content='Loading' />
            </Dimmer>
            <Grid centered columns={3}>
                <Grid.Column textAlign="center">
                    <Header as='h3' textAlign='center' style={{ marginTop: "1em" }}>Product Management
                    </Header>
                    <Input fluid placeholder='Search...'
                        action={{
                            color: 'teal',
                            labelPosition: 'right',
                            icon: 'search',
                            content: 'Search',
                            onClick: findKeyword,
                            className: 'AutumnButton2'
                        }}
                        value={searchKeyword}
                        onChange={onChangeSearchKeyword}
                        onKeyPress={onKeyPress}
                        size='small'
                    />
                </Grid.Column>

                <Grid.Row columns={1} style={{ padding: 0 }}>
                    <Grid.Column textAlign='justified' padded>

                        <Header as='h3' dividing style={{ marginTop: "1em" }}> Product List</Header>

                        {rows.length > 0 &&
                            <Table style={{ marginTop: 20 }} {...getTableProps()}>
                                <Table.Header>
                                    {headerGroups.map((headerGroup) => (
                                        <Table.Row {...headerGroup.getHeaderGroupProps()}>
                                            {headerGroup.headers.map((column) => (
                                                <Table.HeaderCell {...column.getHeaderProps()}>
                                                    {column.render("Header")}
                                                </Table.HeaderCell>
                                            ))}
                                        </Table.Row>
                                    ))}
                                </Table.Header>
                                <Table.Body 
                                    {...getTableBodyProps()}>{recordList(rows, prepareRow)}
                                </Table.Body>
                            </Table>
                        }
                        {rows.length === 0 &&
                         <Message
                         header='Information'
                         content='No records found. try again with another search keyword'
                       />
                        }

                    </Grid.Column>
                </Grid.Row>

                <Grid.Row columns={2}>
                    <Grid.Column textAlign='left' padded>
                        {"Items per Page: "}
                        <select onChange={handlePageSizeChange} value={pageSize} className="ui mini selection dropdown">
                            {pageSizes.map((size) => (
                                <option key={size} value={size}>
                                    {size}
                                </option>
                            ))}
                        </select>
                    </Grid.Column>
                    <Grid.Column textAlign='right' padded>
                        <Pagination
                            boundaryRange={0}
                            activePage={activePage}
                            ellipsisItem={null}
                            firstItem={null}
                            lastItem={null}
                            siblingRange={1}
                            totalPages={count}
                            onPageChange={handlePageChange}
                            pointing
                            secondary
                        />
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        
        <div>
        {currentRow ? (
            <div>
            <h4>Row</h4>
            <div>
                <label>
                <strong>Title:</strong>
                </label>{" "}
                {currentRow.Code}
            </div>
            <div>
                <label>
                <strong>Description:</strong>
                </label>{" "}
                {currentRow.Description}
            </div>

            <Link
                to={"/product-update/" + currentRow.id}
                className="badge badge-warning"
            >
                Edit
            </Link>
            </div>
        ) : (
            <div>
            <br />
            <p>Please click on a search...</p>
            </div>
        )}
        </div>
    </Segment>
    );
}

export default Products
