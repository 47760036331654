import http from "../common/http-common";
import authHeader from './auth.header';

class RegulationService{
    constructor() {}
    get(req){
        return http.get("/regulation/"+req);
    }
}

export default new RegulationService();