import http from "../common/http-common";
import authHeader from './auth.header';
import AuthService from "../services/auth.service";

 class HSCodeService{
    constructor() {
        //let user = AuthService.getCurrentUser();
        //let rs = AuthService.isTokenExpired()
        //if(rs){
        //    AuthService.refreshToken({
        //        "refreshToken":user.refreshToken
        //    }).then(()=>{

        //    });
        //}
    }

    nav(req){
        //return http.post("/hscode/options",req, { headers: authHeader() });
        return http.post("/hscode/options",req);
    }
    navWithCode(req){
        return http.post("/hscode/code/options",req);
    }
    search(req){
        return http.post("/hscode/search",req);
    }

    ask(req){
        return http.post("/hscode/ask",{"keyword":req});
    }

    get(params){
        return http.get("/hscode?page="+params.page+"&size="+params.size+"&keyword="+params.keyword,{ headers: authHeader() });
    }

    add(req){
        return http.post("/hscode/add",req,{ headers: authHeader() });
    }
    update(req){
        return http.post("/hscode/update",req,{ headers: authHeader() });
    }
    delete(req){
        return http.post("/hscode/delete",req,{ headers: authHeader() });
    }
      
}

export default new HSCodeService();