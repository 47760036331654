import http from "../common/http-common";

// current timestamp in milliseconds
let ts = Date.now();

class AuthService{
    signIn(username,password){
        return http.post("/auth/signin",{
            username,
            password
          }).then(response => {
            if (response.data.accessToken) {
              localStorage.setItem("user", JSON.stringify(response.data));
            }
            return response.data;
          });
    }
    refreshToken(req){
        let user = JSON.parse(localStorage.getItem('user'));
        return http.post("/auth/refreshtoken",req).then(response =>{
            user.accessToken = response.data.accessToken;
            user.refreshToken = response.data.refreshToken;
            user.timeStamp = response.data.timeStamp;
            localStorage.setItem("user", user);
            return response.data;
        });;
    }
    signUp({username,email,password}){
        return http.post("/auth/signup",{
            username,
            email,
            password
          });
    }
    
    logout() {
        localStorage.removeItem("user");
    }
    
    getCurrentUser() {
        try{
            if(localStorage.getItem('user')){
                return JSON.parse(localStorage.getItem('user'))
            }else {
                return null;
            }
        }catch(err){
            this.signIn({
                "username":"samabos",
                "password":"p@55w0rd"
            }).then(()=>{
      
            });
        }
    }

    isTokenExpired(){
        let user = this.getCurrentUser();
        // check if token has expired
        const timeSpent = this.timeStampDif(ts,user.timeStamp);
        if(timeSpent > user.jwtExpiration){
           return true;
        }
        return false;
    }

    timeStampDif(timestamp1, timestamp2) {
        var difference = timestamp1 - timestamp2;
        var secDifference = Math.floor(difference/1000);
        return secDifference;
    }
}

export default new AuthService();