import http from "../common/http-common";
import authHeader from './auth.header';

class TarrifService{
    constructor() {}
    get(req){
        return http.get("/tarrif/"+req);
    }
    getbyCode(req){
        return http.get("/tarrif/code/"+req);
    }
}

export default new TarrifService();