import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Container, Button, Icon, Image, Grid } from 'semantic-ui-react';
import UserService from "../services/user.service";
import "../style.css";

export default class Home extends Component {
  constructor(props) {
    super(props);

    this.state = {
      content: ""
    };
  }

  componentDidMount() {
    UserService.getPublicContent().then(
      response => {
        this.setState({
          content: response.data
        });
      },
      error => {
        this.setState({
          content:
            (error.response && error.response.data) ||
            error.message ||
            error.toString()
        });
      }
    );
  }

  render() {
    return (
        <div>
            <br></br>
            <Grid centered columns={3}>
            <Grid.Column>
            <Image src='/large-logo.svg' centered/>
            <p className="AutumnHomeText">Harmozised Commodity Codes and Tariff Classification</p>
            </Grid.Column>
            <Grid.Row columns={2} style={{padding:0}}>
            <Grid.Column padded>
                <Link to="/search">
                    <Image src='/images/classification.svg' centered />
                </Link>
            </Grid.Column>
            <Grid.Column padded>
                <Link to="/duty-calculator">
                    <Image src='/images/calculator.svg' centered />
                </Link>
            </Grid.Column>
            </Grid.Row>
            <Grid.Row centered  columns={2} style={{padding:0}}>
            <Grid.Column textAlign='center' padded>
                <Button>Product Classification</Button>
            </Grid.Column>
            <Grid.Column textAlign='center' padded>
                <Button centered>Duty Calculator</Button>
            </Grid.Column> 
            </Grid.Row>
        </Grid>
      </div>
    );
  }
}

