import http from "../common/http-common";
import authHeader from './auth.header';

class UserService{
    getPublicContent() {
        return http.get("/account/" + 'all');
      }
    
      getUserBoard() {
        return http.get("/account/" + 'user', { headers: authHeader() });
      }
    
      getModeratorBoard() {
        return http.get("/account/" + 'mod', { headers: authHeader() });
      }
    
      getAdminBoard() {
        return http.get("/account/" + 'admin', { headers: authHeader() });
      }
}

export default new UserService();