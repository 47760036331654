import React, { Component, useState, useEffect, useMemo, useRef } from "react";
import { Link, Navigate, useNavigate } from "react-router-dom";
import { useTable } from "react-table";
import { Grid, Input, Header, Message, Table, Pagination, Dimmer, Loader, Segment,Button,Icon,Form } from 'semantic-ui-react'
import HSCodeService from "../../../services/hscode.service";
import EventBus from "../../../common/EventBus";
import AuthService from "../../../services/auth.service";



const Record = (props) => (
    <Table.Row {...props.record.getRowProps()}>
        {props.record.cells.map((cell) => {
                    return (
                      <Table.Cell {...cell.getCellProps()}>{cell.render("Cell")}</Table.Cell>
                    );
                  })}
    </Table.Row>
   );
const pageSizes = [10, 25, 50, 100, 500];

const HSCodes =()=>{
    const [loading, setLoading] = useState("");
    const [records, setRecords] = useState([]);
    const [currentRow, setCurrentRow] = useState(null);
    const [currentIndex, setCurrentIndex] = useState(-1);
    const [searchKeyword, setSearchKeyword] = useState("");
    const [open, setOpen] = useState(false);
    const [activePage, setActivePage] = useState(1);
    const [count, setCount] = useState(0);
    const [pageSize, setPageSize] = useState(10);
    const [editedValues, setEditedValues] = useState({
        _id:"",
        Order: "",
        Level: "",
        ParentId: "",
        Code: "",
        ParentCode: "",
        Description: "",
        SelfExplanatory: "",
        // Add other fields here
      });

    const onChangeSearchKeyword = (e) => {
        const searchKeyword = e.target.value;
        setSearchKeyword(searchKeyword);
        //setActivePage(1);
    }
    const getRequestParams = (searchKeyword,page,pageSize) => {
        let params = {};
            if (searchKeyword) {
                params["keyword"] = searchKeyword;
            }
            if (page) {
                params["page"] = page - 1;
            }
            if (pageSize) {
                params["size"] = pageSize;
            }
        return params;
    };
      
    const findKeyword = (event) => {
        retrieveHSCodes();
        setActivePage(1);
    };
    
    const handlePageChange = (e, { activePage }) => {
        setActivePage(activePage);
    };
    
    const handlePageSizeChange = (event) => {
        setActivePage(1);
        setPageSize(event.target.value);
    };
    const onKeyPress= (e) => {
        if(e.key === 'Enter')
        retrieveHSCodes();
    }
    const refreshList=(event)=>{
        retrieveHSCodes();
        setCurrentRow = null;
        setCurrentIndex = event.target.index;
    }
    const setActiveRow=(event)=>{
        setCurrentRow = event.target.Record;
        setCurrentIndex = event.target.index;
    }
  
       // This method will delete a record
    const deleteRecord=()=> {
        HSCodeService.delete(searchKeyword)
        .then(response => {
            console.log(response.data);
            setRecords(response.data.payload.records)
           
        }).catch(e=>{
            console.log(e);
        });
    }
    const editRecord = (rowIdx,editedRecord) => {
        //const editedRecord = records[rowIdx];
        //console.log(rowIdx)
        //console.log(records)
        console.log(editedRecord)
        setEditedValues({
        _id:editedRecord._id,
        Order: editedRecord.Order,
        Level: editedRecord.Level,
        ParentId: editedRecord.ParentId,
        Code: editedRecord.Code,
        ParentCode: editedRecord.ParentCode,
        Description: editedRecord.Description,
        SelfExplanatory: editedRecord.SelfExplanatory,
        // Set other fields accordingly
        });
        setCurrentRow(editedRecord);
        setCurrentIndex(rowIdx);
    };
    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setEditedValues((prevValues) => ({
          ...prevValues,
          [name]: value,
        }));
    };
    
      const updateRecord = () => {
        const updatedData = {
          id:editedValues._id,
          Order: editedValues.Order,
          Level: editedValues.Level,
          ParentId: editedValues.ParentId,
          Code: editedValues.Code,
          ParentCode: editedValues.ParentCode,
          Description: editedValues.Description,
          SelfExplanatory: editedValues.SelfExplanatory,
          // Set other fields accordingly
        };
    
        HSCodeService.update(updatedData)
          .then((response) => {
            console.log(response.data);
            setEditedValues({
              _id:"",
              Order: "",
              Level: "",
              ParentId: "",
              Code: "",
              ParentCode: "",
              Description: "",
              SelfExplanatory: "",
              // Clear other fields
            });
            setCurrentRow(null);
            setCurrentIndex(-1);
            retrieveHSCodes();
          })
          .catch((error) => {
            console.error(error);
          });
      };
    

    const columns = useMemo(
        () => [
            {
                Header: '#',
                accessor: 'serial'
            },
          {
            Header: "Code",
            accessor: "Code",
          },
          {
            Header: "Description",
            accessor: "Description",
          },
          {
            Header: "ParentCode",
            accessor: "ParentCode",
          },
          {
            Header: "Level",
            accessor: "Level",
          },
          {
            Header: "Order",
            accessor: "Order",
          },
          {
            Header: "Actions",
            accessor: "actions",
            Cell: (props) => {
              return (
                <div className="action-icons">
                    <Icon
                    name="edit"
                    color="black"
                    bordered
                    link
                    onClick={() => editRecord(props.row.id,props.row.original)} // Add this line for edit action
                    />
                    {/**
                    <Icon
                    name="delete"
                    color="red"
                    bordered
                    link
                    onClick={() => deleteRecord(rowIdx)} // Add this line for delete action
                    /> */}
                </div>
              );
            },
          },
        ],
        []
      );

    const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    } = useTable({
    columns,
    data: records,
    });

    const recordList=() =>{
        return rows.map((record, i) => {
            prepareRow(record);
            return (
                <Record
                record={record}
                deleteRecord={() => deleteRecord()}
                key={record._id}
                />
            );
        });
      }

      const navigate = useNavigate();
      const retrieveHSCodes = async () => {
        try {
          setLoading("active");
          const params = getRequestParams(searchKeyword, activePage, pageSize);
          console.log(params);
          
          const response = await HSCodeService.get(params);
          console.log(response.data); // Log the API response
          
          const { records, totalPages } = response.data.payload;
          const updatedRecords = records.map((record, index) => ({
            ...record,
            serial: index + 1 + (activePage - 1) * pageSize,
          }));
          
          console.log(updatedRecords); // Log the updated records
          
          setRecords(updatedRecords);
          setCount(totalPages);
          setLoading("");
        } catch (error) {
          if (error.response && (error.response.status === 401 || error.response.status === 403)) {
            EventBus.dispatch("logout");
          }
          console.error(error);
          setLoading("");
        }
      };
      useEffect(() => {
        const currentUser = AuthService.getCurrentUser();
        const goToHomePage = () => navigate('/home');
        
        if (!currentUser) {
          goToHomePage();
        }
        
        const fetchData = async () => {
          await retrieveHSCodes();
          console.log('Inside useEffect:', records); // Log the updated records after the component re-renders
        };
        
        fetchData();
      }, [activePage, pageSize, navigate]);
      
      
      return (
        <Segment>
            <Dimmer active={loading} inverted>
            <Loader content='Loading' />
            </Dimmer>
            <Grid centered columns={3}>
                <Grid.Column textAlign="center">
                    <Header as='h3' textAlign='center' style={{ marginTop: "1em" }}>HS Code Management
                    </Header>
                    <Input fluid placeholder='Search...'
                        action={{
                            color: 'teal',
                            labelPosition: 'right',
                            icon: 'search',
                            content: 'Search',
                            onClick: findKeyword,
                            className: 'AutumnButton2'
                        }}
                        value={searchKeyword}
                        onChange={onChangeSearchKeyword}
                        onKeyPress={onKeyPress}
                        size='small'
                    />
                </Grid.Column>

                <Grid.Row columns={1} style={{ padding: 0 }}>
                    <Grid.Column textAlign='justified' padded>

                        <Header as='h3' dividing style={{ marginTop: "1em" }}> HS Code List</Header>

                        {rows.length > 0 &&
                            <Table style={{ marginTop: 20 }} {...getTableProps()}>
                                <Table.Header>
                                    {headerGroups.map((headerGroup) => (
                                        <Table.Row {...headerGroup.getHeaderGroupProps()}>
                                            {headerGroup.headers.map((column) => (
                                                <Table.HeaderCell {...column.getHeaderProps()}>
                                                    {column.render("Header")}
                                                </Table.HeaderCell>
                                            ))}
                                        </Table.Row>
                                    ))}
                                </Table.Header>
                                <Table.Body 
                                    {...getTableBodyProps()}>{recordList(rows, prepareRow)}
                                </Table.Body>
                            </Table>
                        }
                        {rows.length === 0 &&
                         <Message
                         header='Information'
                         content='No records found. try again with another search keyword'
                       />
                        }

                    </Grid.Column>
                </Grid.Row>

                <Grid.Row columns={2}>
                    <Grid.Column textAlign='left' padded>
                        {"Items per Page: "}
                        <select onChange={handlePageSizeChange} value={pageSize} className="ui mini selection dropdown">
                            {pageSizes.map((size) => (
                                <option key={size} value={size}>
                                    {size}
                                </option>
                            ))}
                        </select>
                    </Grid.Column>
                    <Grid.Column textAlign='right' padded>
                        <Pagination
                            boundaryRange={0}
                            activePage={activePage}
                            ellipsisItem={null}
                            firstItem={null}
                            lastItem={null}
                            siblingRange={1}
                            totalPages={count}
                            onPageChange={handlePageChange}
                            pointing
                            secondary
                        />
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        
        <div>
        {currentRow ? (
          <div>
            <h4>Edit Row</h4>
            <Form>
              <Form.Field>
                <label>Order</label>
                <input
                  type="text"
                  name="Order"
                  value={editedValues.Order}
                  onChange={handleInputChange}
                />
              </Form.Field>
              <Form.Field>
                <label>Level</label>
                <input
                  type="text"
                  name="Level"
                  value={editedValues.Level}
                  onChange={handleInputChange}
                />
              </Form.Field>
              <Form.Field>
                <label>Parent ID</label>
                <input
                  type="text"
                  name="ParentId"
                  value={editedValues.ParentId}
                  onChange={handleInputChange}
                />
              </Form.Field>
              <Form.Field>
                <label>Code</label>
                <input
                  type="text"
                  name="Code"
                  value={editedValues.Code}
                  onChange={handleInputChange}
                />
              </Form.Field>
              <Form.Field>
                <label>Parent Code</label>
                <input
                  type="text"
                  name="ParentCode"
                  value={editedValues.ParentCode}
                  onChange={handleInputChange}
                />
              </Form.Field>
              <Form.Field>
                <label>Description</label>
                <input
                  type="text"
                  name="Description"
                  value={editedValues.Description}
                  onChange={handleInputChange}
                />
              </Form.Field>
              <Form.Field>
                <label>Self Explanatory</label>
                <input
                  type="text"
                  name="SelfExplanatory"
                  value={editedValues.SelfExplanatory}
                  onChange={handleInputChange}
                />
              </Form.Field>
              {/* Add more Form.Field components for other fields */}
              <Button color="blue" onClick={updateRecord}>
                Update
              </Button>
            </Form>
          </div>
        ): (
            <div>
            <br />
            <p>Please click on a search...</p>
            </div>
        )}
        </div>
    </Segment>
    );
}

export default HSCodes
