import React, { Component } from "react";
//import { useNavigate } from "react-router";
import NavigationService from "../../services/hscode.service";
import { Link } from "react-router-dom";
import { List, Button, Input, Header, Message, Label, Segment } from 'semantic-ui-react'

//import AuthService from "../../services/auth.service";
import Note from "./note.component";

export default class Import extends Component{
    constructor(props){
        super(props);
        this.onChangeSearchKeyword = this.onChangeSearchKeyword.bind(this);
        this.retrieveHSCodes = this.retrieveHSCodes.bind(this);
        this.refreshList = this.refreshList.bind(this);
        this.setActiveRow = this.setActiveRow.bind(this);
        this.searchKeyword = this.searchKeyword.bind(this);
        this.navNext = this.navNext.bind(this);
        this.navBack = this.navBack.bind(this);

        this.state = {
            rows:[],
            predictions:[],
            currentRow:null,
            currentIndex:-1,
            searchKeyword:"",
            open:false
        };
    }

    componentDidMount(){
       
        this.retrieveHSCodes();
    }
    onChangeSearchKeyword = (e) => {
        //alert('yes');
        const searchKeyword = e.target.value;
        //console.log(searchKeyword);
        this.setState({
            searchKeyword: searchKeyword
        });
        //alert(searchKeyword);
    }
    retrieveHSCodes(req){
        NavigationService.nav(req)
            .then(response=>{
                this.setState({
                    rows:response.data.payload,
                    predictions:[]
                });
                console.log(response.data);
            }).catch(e=>{
                console.log(e)
            });
    }

    refreshList(){
        this.retrieveHSCodes();
        this.setState({
            currentRow: null,
            currentIndex:-1
        });
    }

    setActiveRow(row,index){
        this.setState({
            currentRow:row,
            currentIndex:index
        });
    }

    searchKeyword(){
        //alert("ask");
        NavigationService.ask(this.state.searchKeyword)
            .then(response => {
                console.log(response.data);
                this.setState({
                    rows:response.data.payload.hscode,
                    predictions:response.data.payload.prediction
                });
            }).catch(e=>{
                console.log(e);
            });
    }
    navNext(row,index){
        console.log(row);
        let level = row.Level+1;
        if(row.Code.length < 7){
            this.retrieveHSCodes({
                "id":"",
                "pid":row.Id,
                "level":level
            });
        }
    }
    navBack(row,index){
        console.log(row);
        let level = row.Level-1;
        if(level > 0){
            this.retrieveHSCodes({
                "id":row.ParentId,
                "pid":"",
                "level":level
            });
        }
    }

    render(){
        const {searchKeyword, rows, predictions, currentRow, currentIndex} = this.state;

        return (
            <div>
                 <Header textAlign='center' style={{marginTop:"1em"}} className="AutumnHomeText"
                  as='h3' 
                  content='PRODUCT CLASSIFICATION'
                  subheader='Classify commodity to get imformation about duty, levy and VAT. Also find out about regulatory requirements for import and export.'
                />
                <Header as='h3' textAlign='center' style={{marginTop:"1em"}}>Search for Commodity Codes
                </Header>

                <div>
                        <Input fluid placeholder='Search...'
                            action={{content:'Search', className:'PrimaryBackground', 
                            onClick: this.searchKeyword,}}
                            value={searchKeyword}
                            onChange={this.onChangeSearchKeyword}
                            size = 'big'
                        />
                </div>

                <div>
                    <Header as='h3' dividing  style={{marginTop:"1em"}}> Harmonised Commodity Code</Header>
                   <Segment color='grey'>
                   <List selection  relaxed verticalAlign='middle'>
                        {rows && rows.map((row, index) => (
                            <List.Item style={{margin: "0.5em 0", padding:"0.5em"}}
                            className={
                                (index === currentIndex ? "active" : "")
                            }
                            onClick={() => this.setActiveRow(row, index)}
                            key={index}
                            >
                            <List.Content floated='left' style={{padding: "0"}}>
                                <Button basic size='small' icon='angle left'
                                onClick={()=>this.navBack(row,index)} />
                            </List.Content>
                            <List.Content floated='right' style={{padding: "0"}}>
                                <Button basic size='small' icon='angle right'
                                onClick={()=>this.navNext(row,index)} />
                            </List.Content>
                             <List.Content style={{padding: "0 6em 1em 6em"}}>
                             {
                              row.Code.length >= 7 ? 
                              <Note row={row} /> :
                             <List.Header >{row.Code} </List.Header>
                             }
                                
                                    
                                <List.Description>
                                    {row.Description}
                                    {predictions.length > 0? 
                                    <Label right>
                                    Confidence Score
                                    <Label.Detail>
                                        {Math.round(predictions.find(e=>e.hscode === row.Code).accuracy)} 
                                        </Label.Detail>
                                    </Label>
                                        : ""}
                                </List.Description>
                                    
                             </List.Content>
                            </List.Item>
                        ))}
                    </List>
                   </Segment>
                </div>
                <div>
                {currentRow ? (
                    <div>
                    <div>
                        <label>
                        <strong>Title:</strong>
                        </label>{" "}
                        {currentRow.Code}
                    </div>
                    <div>
                        <label>
                        <strong>Description:</strong>
                        </label>{" "}
                        {currentRow.Description}
                    </div>
                    </div>
                ) : (
                    <div>
                    <br />
                    <p>Please click on a search...</p>
                    </div>
                )}
                </div>
            </div>
        );
    }
}
