
import AuthService from "../services/auth.service";

// current timestamp in milliseconds
let ts = Date.now();

//function  setDefaultUser(){
//    AuthService.signIn({
//        "username":"samabos",
//        "password":"p@55w0rd"
//    }).then(()=>{

//    });
//}

async function refrestToken(token){
    return await AuthService.refreshToken({
        "refreshToken":token
    });
}

function timeStampDif(timestamp1, timestamp2) {
    var difference = timestamp1 - timestamp2;
    var secDifference = Math.floor(difference/1000);
    return secDifference;
}

export default function authHeader() {
    let user = JSON.parse(localStorage.getItem('user'));
    //if(!user || !user.timeStamp){
    //    setDefaultUser();
    //    user = JSON.parse(localStorage.getItem('user'));
    //}
    // check if token has expired
    //const timeSpent = timeStampDif(ts,user.timeStamp);
    //if(timeSpent > user.jwtExpiration){
    //    let res = await refrestToken(user.refreshToken);
    //    user.accessToken = res.accessToken;
    //    user.refreshToken = res.refreshToken;
    //    user.timeStamp = res.timeStamp;
    //    return { 'x-access-token': user.accessToken };
    //}else{
        if (user && user.accessToken) {
        // for Node.js Express back-end
            return { 'x-access-token': user.accessToken };
        } else {
            return {};
        }
    //}
  }