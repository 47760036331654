import http from "../common/http-common";
import authHeader from './auth.header';

 class ProductService{
    constructor() {}
    get(params){
        return http.get("/product?page="+params.page+"&size="+params.size+"&keyword="+params.keyword,{ headers: authHeader() });
    }
    getbyId(req){
        return http.get("/product"+req,{ headers: authHeader() });
    }
    search(req){
        return http.get("/product/keyword"+req,{ headers: authHeader() });
    }
    add(req){
        return http.post("/product/add",req,{ headers: authHeader() });
    }
    update(req){
        return http.post("/product/update",req,{ headers: authHeader() });
    }
    delete(req){
        return http.post("/product/delete",req,{ headers: authHeader() });
    }
}

export default new ProductService();