import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import TarrifService from "../../services/tarrif.service";
import RegulationService from "../../services/regulation.service";
import { List, Button, Header, Modal, Table } from 'semantic-ui-react'


function Tarrif({data}){
   
    return  <Table basic='very' celled collapsing unstackable>
    <Table.Header>
    <Table.Row>
        <Table.HeaderCell>Tariff Code</Table.HeaderCell>
        <Table.HeaderCell>DUTY</Table.HeaderCell>
        <Table.HeaderCell>VAT</Table.HeaderCell>
        <Table.HeaderCell>LEVY</Table.HeaderCell>
        <Table.HeaderCell>SUR</Table.HeaderCell>
        <Table.HeaderCell>ETLS</Table.HeaderCell>
        <Table.HeaderCell>NAC</Table.HeaderCell>
        <Table.HeaderCell>CISS</Table.HeaderCell>
        <Table.HeaderCell>Local Description</Table.HeaderCell>
    </Table.Row>
    </Table.Header>

    <Table.Body>
  {data && data.map((row, index) => (
    Object.is(row,null)?
          <Table.Row> <Table.Cell> No record found.</Table.Cell></Table.Row>
    :
        <Table.Row>
            <Table.Cell>{Object.is(row.HSCode,null)?"n/a":<Link to={'/duty-calculator/'+row.HSCode}>{row.HSCode}</Link>}</Table.Cell>
            <Table.Cell>{row.DUTY['$numberDecimal'].toLocaleString()}%</Table.Cell>
            <Table.Cell>{row.VAT['$numberDecimal'].toLocaleString()}%</Table.Cell>
            <Table.Cell>{row.LEVY['$numberDecimal'].toLocaleString()}%</Table.Cell>
            <Table.Cell>{row.SUR['$numberDecimal'].toLocaleString()}%</Table.Cell>
            <Table.Cell>{row.ETLS['$numberDecimal'].toLocaleString()}%</Table.Cell>
            <Table.Cell>{row.NAC['$numberDecimal'].toLocaleString()}%</Table.Cell>
            <Table.Cell>{row.CISS['$numberDecimal'].toLocaleString()}%</Table.Cell>
            <Table.Cell>{row.Description}</Table.Cell>
        </Table.Row>

  ))}
    </Table.Body>
</Table>
}

function Regulators({dataReg}){
   
  return  <Table basic='very' celled collapsing unstackable>
  <Table.Header>
  <Table.Row>
      <Table.HeaderCell>Issuer</Table.HeaderCell>
      <Table.HeaderCell>National HSCode</Table.HeaderCell>
      <Table.HeaderCell>Import Guidelines</Table.HeaderCell>
      <Table.HeaderCell>Application Form</Table.HeaderCell>
      <Table.HeaderCell>Documents Delivered</Table.HeaderCell>
      <Table.HeaderCell>Estimated Time</Table.HeaderCell>
      <Table.HeaderCell>Form Cost(NGN)</Table.HeaderCell>
      <Table.HeaderCell>Product Cost(NGN)</Table.HeaderCell>
  </Table.Row>
  </Table.Header>
  <Table.Body>
  {dataReg && dataReg.map((row, index) => (

    Object.is(row,null)?
          <Table.Row> <Table.Cell> No record found.</Table.Cell></Table.Row>
    :
    <Table.Row> 
          <Table.Cell>{row.Agency}</Table.Cell>
          <Table.Cell>{row.HSCodeLocal}</Table.Cell>
          <Table.Cell>{row.ImportGuidelines}</Table.Cell>
          <Table.Cell>{row.Forms}</Table.Cell>
          <Table.Cell>{row.DocumentsDelivered}</Table.Cell>
          <Table.Cell>{row.EstimatedTime}</Table.Cell>
          <Table.Cell>{row.FormCost}</Table.Cell>
          <Table.Cell>{row.ProductCost}</Table.Cell>
      </Table.Row>
    
  ))}
  </Table.Body>
</Table>
}

const Note = (props) => {
    let [open, setOpen] = useState(false);
    let [data, setData] = useState({});
    let [dataReg, setDataReg] = useState({});
    let row = props.row;

    useEffect(()=>{
      let code = props.row.Code;
          const fetchTariff = async () =>{
            TarrifService.get(code)
            .then(response=>{
              //console.log(code);
              //console.log(response.data.payload);
              setData(response.data.payload);
            }).catch(e=>{
              console.log(e);
            });
          };
          fetchTariff();
          const fetchRegulation = async () =>{
            RegulationService.get(code)
            .then(response=>{
              //console.log(code);
              console.log(response.data.payload);
              setDataReg(response.data.payload);
            }).catch(e=>{
              console.log(e);
            });
          };
          fetchRegulation();
    },[props]);
    

    return (
      <Modal
        onClose={() => setOpen(false)}
        onOpen={() => setOpen(true)}
        open={open}
        trigger={<List.Header as={row.Code.length >= 7 ? "a":""}>{row.Code}</List.Header>}
        size='fullscreen'
      >
        <Modal.Header>Code: {row.Code}</Modal.Header>
        <Modal.Content>
          <Modal.Description>
            <Header>1. Explanatory Note</Header>
            <p>
                {row.SelfExplanatory}
            </p>
            <Header>2. Customs Tariff</Header>
            <Tarrif data={data} />
            {/*<Header>3. Import Regulatory Requirement</Header>
            <Regulators dataReg={dataReg}/>*/}
          </Modal.Description>
        </Modal.Content>
        <Modal.Actions>
          <Button 
            content="Close"
            labelPosition='right'
            icon='close' 
            color='black' 
            onClick={() => setOpen(false)}
            />
            
          {/*<Button
            content="Yep, that's me"
            labelPosition='right'
            icon='checkmark'
            onClick={() => setOpen(false)}
            positive
    />*/}
        </Modal.Actions>
      </Modal>
    )
  }
  
export default Note