import React, { Component } from "react";
 
// We use Route in order to define the different routes of our application
import { Routes, Route, Link } from "react-router-dom";
import { Container, Menu, Dropdown, Icon } from 'semantic-ui-react';
import "./style.css";
import AuthService from "./services/auth.service";
import AuthVerify from "./common/auth-verify";
import EventBus from "./common/EventBus";
 
// We import all the components we need in our app
import Login from "./components/account/login.component";
import Register from "./components/account/register.component";
import Home from "./components/home.component";
import About from "./components/home.component";
import Development from "./components/home.component";
import Contact from "./components/home.component";
import Profile from "./components/account/profile.component";

import ProductSearch from "./components/classification/import.component";
import DutyCalculator from "./components/calculator/duty.component";

import ProductManager from "./components/backoffice/products/products.component";
import ProductAdd from "./components/backoffice/products/create.component";
import ProductUpdate from "./components/backoffice/products/update.component";

import HSManager from "./components/backoffice/hscodes/hscodes.component";

//import Navbar from "./components/navbar.component";
//import RecordList from "./components/classification/import.component";
//import Edit from "./components/edit.component";
//import Create from "./components/create.component";

class App extends Component {
  constructor(props) {
    super(props);
    this.logOut = this.logOut.bind(this);

    this.state = {
      showModeratorBoard: false,
      showAdminBoard: false,
      currentUser: undefined,
    };
  }

  componentDidMount() {
    const user = AuthService.getCurrentUser();

    if (user) {
      this.setState({
        currentUser: user,
        showModeratorBoard: user.roles.includes("ROLE_MODERATOR"),
        showAdminBoard: user.roles.includes("ROLE_ADMIN"),
      });
    }
    EventBus.on("logout", () => {
      this.logOut();
    });
  }
  componentWillUnmount() {
    EventBus.remove("logout");
  }

  logOut() {
    AuthService.logout();
    this.setState({
      showModeratorBoard: false,
      showAdminBoard: false,
      currentUser: undefined,
    });
  }

  render() {
    const { currentUser, showModeratorBoard, showAdminBoard } = this.state;

    return (
      <div>
        <div className="PrimaryLight">
          <Container borderless className="AutumnHeader">
          <Menu stackable size='small' className="AutumnMenu" borderless pointing >
        <Menu.Item header>
          <Link to={"/"}>
          <img alt="logo" src='/logo.svg' fluid small height={30}/>
          </Link>
          </Menu.Item>
          <Menu.Menu position='right'>
          <Menu.Item>
              <Link to={"/home"} className="nav-link">
                Home
              </Link>
            </Menu.Item>
          <Menu.Item>
              <Link to={"/search"} className="nav-link">
                Classification
              </Link>
            </Menu.Item>
          <Menu.Item>
              <Link to={"/duty-calculator"} className="nav-link">
                Calculator
              </Link>
            </Menu.Item>
          


            {showModeratorBoard && (
              <Dropdown item text='More'>
              <Dropdown.Menu>
                <Dropdown.Item icon='edit'>
                <Link to={"/products"} className="nav-link">
                  Manage Products
                </Link>
                </Dropdown.Item> 
                <Dropdown.Item icon='globe'>
                <Link to={"/hscodes"} className="nav-link">
                    Manage HS Code
                  </Link>
                </Dropdown.Item>
                <Dropdown.Item icon='globe'>
                <Link to={"/hscodes"} className="nav-link">
                    Manage Users
                  </Link>
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>

              
            )}

            {showAdminBoard && (
              <Dropdown item text='Resources'>
              <Dropdown.Menu>
                <Dropdown.Item icon='edit'>
                <Link to={"/products"} >
                  Manage Products
                </Link>
                </Dropdown.Item> 
                <Dropdown.Item icon='globe'>
                <Link to={"/hscodes"}>
                    Manage HS Code
                  </Link>
                </Dropdown.Item>
                <Dropdown.Item icon='globe'>
                <Link to={"/hscodes"}>
                    Manage Users
                  </Link>
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
            )}
          </Menu.Menu>
          

          

          {currentUser ? (
            <Menu.Menu position='right'>
              <Menu.Item>
              <Link to={"/profile"} className="nav-link">
                <Icon circular name='user'/> {currentUser.username}
              </Link>
            </Menu.Item><Menu.Item>
                <a href="/login" className="nav-link" onClick={this.logOut}>
                  LogOut
                </a>
              </Menu.Item>
              </Menu.Menu>
          ) : (
            <Menu.Menu position='right'>
            <Menu.Item>
                <Link to={"/login"} className="nav-link">
                  Login
                </Link>
              </Menu.Item>

              <Menu.Item>
                <Link to={"/register"} className="nav-link">
                  Sign Up
                </Link>
              </Menu.Item>
            </Menu.Menu>
          )}
        </Menu>
          </Container>
        </div>
        

        <Container>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/home" element={<Home />} />
            <Route path="/login" element={<Login />} />
            <Route path="/register" element={<Register />} />
            <Route path="/profile" element={<Profile />} />
            
            <Route path="/search" element={<ProductSearch />} />
            <Route path="/duty-calculator" element={<DutyCalculator />} />
            <Route path="/duty-calculator/:code" element={<DutyCalculator />} />

            <Route path="/products" element={<ProductManager />} />
            <Route path="/product-add" element={<ProductAdd />} />
            <Route path="/product-update" element={<ProductUpdate />} />

            <Route path="/hscodes" element={<HSManager />} />
          </Routes>
          </Container>

          <AuthVerify logOut={this.logOut}/>
      </div>
    );
  }
}

export default App;



 
