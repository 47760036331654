import http from "../common/http-common";
import authHeader from './auth.header';

class CurrencyService{
    constructor() {}
    get(){
        return http.get("/currency");
    }

    getbyCurrency(req){
        return http.get("/currency/"+req);
    }
}

export default new CurrencyService();