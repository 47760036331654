import React, { Component } from "react";
//import Form from "react-validation/build/form";
//import Input from "react-validation/build/input";
import { FormField, Button, Icon, Image, Grid, Card, Checkbox, Input, Form } from 'semantic-ui-react';
import "../../style.css";

import AuthService from "../../services/auth.service";

import { withRouter } from '../../common/with-router';


  class Login extends Component {
    constructor(props) {
      super(props);
      this.handleLogin = this.handleLogin.bind(this);
      this.onChangeUsername = this.onChangeUsername.bind(this);
      this.onChangePassword = this.onChangePassword.bind(this);
  
      this.state = {
        username: "",
        password: "",
        loading: false,
        message: ""
      };
    }


  onChangeUsername(e) {
    this.setState({
      username: e.target.value
    });
  }

  onChangePassword(e) {
    this.setState({
      password: e.target.value
    });
  }

  handleLogin(e) {
    e.preventDefault();

    this.setState({
      message: "",
      loading: true
    });


      AuthService.signIn(this.state.username, this.state.password).then(
        () => {
          this.props.router.navigate("/profile");
          window.location.reload();
        },
        error => {
          const resMessage =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();

          this.setState({
            loading: false,
            message: resMessage
          });
        }
      );
  }

  render() {
    return (
      <div>
      <br></br>
      <Grid centered columns={3}>
      <Grid.Column>
            <Image src='/large-logo.svg' centered/>
            <p centered className="AutumnHomeText">hs.codes</p>
      </Grid.Column>

      <Grid.Row columns={3} style={{padding:0}}>
      <Grid.Column textAlign='center' padded>
          <Card className="AutumnLoginBox">
            <Card.Content>
            <br></br>
            <Card.Header>Login</Card.Header>
            <br></br>
            <br></br>
              <Form instantValidate={true}
              onSubmit={this.handleLogin}
              ref='form'>
               <div className="AlignLeft">
               <FormField>
                <label>Username</label>
               <Input fluid
                  type="text"
                  name="username"
                  value={this.state.username}
                  onChange={this.onChangeUsername}
                  validators={['required']}
                  errorMessages={['Username is required']}
                  width="12"
                  inline={false}
                />
                </FormField>
                <FormField>
                <label>Password</label>
                <Input
                  type="password"
                  name="password"
                  value={this.state.password}
                  onChange={this.onChangePassword}
                  validators={['required']}
                  errorMessages={['Password is required']}
                />
                </FormField>

<Checkbox toggle label="Remember me"/>

<p className="small"><br></br>Forgot password? Click here</p>
               </div>

<br></br>
                <Button className="AutumnButton">
                  Login
                </Button>

                <p className="small"><br></br>Don’t have an account? Register here</p>
            
            
            </Form>
            </Card.Content>
          </Card>
        </Grid.Column>
        </Grid.Row>
      </Grid>
      </div>
    );
  }
}

export default withRouter(Login);
