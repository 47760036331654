import React, { Component } from "react";
import { Navigate } from "react-router-dom";
import AuthService from "../../services/auth.service";
import { Card, Icon, Image } from 'semantic-ui-react'

export default class Profile extends Component {
  constructor(props) {
    super(props);

    this.state = {
      redirect: null,
      userReady: false,
      currentUser: { username: "" }
    };
  }

  componentDidMount() {
    const currentUser = AuthService.getCurrentUser();

    if (!currentUser) this.setState({ redirect: "/home" });
    this.setState({ currentUser: currentUser, userReady: true })
  }

  render() {
    if (this.state.redirect) {
      return <Navigate to={this.state.redirect} />
    }

    const { currentUser } = this.state;

    return (
      <div className="container">
        {(this.state.userReady) ?
        <div>
           <Card>
            <Image src='/images/user.png' wrapped ui={true} />
            <Card.Content>
              <Card.Header>{currentUser.username}</Card.Header>
              <Card.Meta>
                <span className='date'>Active acount</span>
              </Card.Meta>
              <Card.Description>
              {currentUser.username} hs.codes system profile.
              <p>
                <strong>Email:</strong>{" "}
                {currentUser.email}
              </p>
              </Card.Description>
            </Card.Content>
            <Card.Content extra>
              <strong>Authorities:</strong>
              <ul>
                {currentUser.roles &&
                  currentUser.roles.map((role, index) => <li key={index}>{role}</li>)}
              </ul>
             
            </Card.Content>
          </Card>
     
       {/** <p>
          <strong>Token:</strong>{" "}
          {currentUser.accessToken.substring(0, 20)} ...{" "}
          {currentUser.accessToken.substr(currentUser.accessToken.length - 20)}
        </p>
        <p>
          <strong>Id:</strong>{" "}
          {currentUser.id}
        </p> */}
       
        
      </div>: null}
      </div>
    );
  }
}