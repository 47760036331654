import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Form, Input, Button, Select, Table, Header, Segment, Message } from 'semantic-ui-react';
import UserService from "../../services/user.service";
import TariffService from "../../services/tarrif.service";
import CurrencyService from "../../services/currency.service";
import "../../style.css";



const DutyCalculator = () => {
  const { code } = useParams();

  const [currencyOptions, setCurrencyOptions] = useState([]);
  const [formValues, setFormValues] = useState({
    ProductDesc: '',
    HSCode: code,
    Cost: 0,
    Freight: 0,
    Insurance: 0,
    Currency: '',
    ExRate: 0,
    CF: 0,
    CIF: 0,
    CIFLocal: 0,
    IDRate: 0,
    VATRate: 0,
    ETLRate: 0,
    SURRate: 0,
    CISSRate: 0,
    NACRate: 0,
    LEVYRate: 0,
    IDPayableLocal: 0,
    VATPayableLocal: 0,
    ETLPayableLocal: 0,
    SURPayableLocal: 0,
    CISSPayable: 0,
    CISSPayableLocal: 0,
    NACPayable: 0,
    NACPayableLocal: 0,
    LEVYPayableLocal: 0,
    TotalPayableLocal: 0,
    HSCodeDescription: '',
    DisplayResult: false,
    errorMessage: '',
  });

  useEffect(() => {
    // Fetch currency options
    fetchCurrencyOptions();
  }, []);

  const fetchCurrencyOptions = async () => {
    try {
      const response = await CurrencyService.get();
      if (!response.data.successful) {
        throw new Error(response.data.message);
      }

      const currencyList = response.data.payload;

      // Transform currencyList into the format expected by the Select component
      const options = currencyList.map((currency) => ({
        key: currency._id,
        text: currency.CurrencyCode,
        value: currency.CurrencyCode,
      }));

      setCurrencyOptions(options);
    } catch (error) {
      console.error('Error fetching currency options:', error.message);
    }
  };

  const handleInputChange = (e, { name, value }) => {
    setFormValues({ ...formValues, [name]: value });
  };

  const handleSelectChange = (event, data) => {
    // Update the state with the selected currency
    setFormValues({ ...formValues, Currency: data.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    // Fetch data from services
    try {
      const tariffResp = await TariffService.getbyCode(formValues.HSCode);
      const currencyResp = await CurrencyService.getbyCurrency(formValues.Currency);
      if (!tariffResp.data.successful || !currencyResp.data.successful) {
        throw new Error(`${tariffResp.data.message}-${currencyResp.data.message}`);
      }
      const tariffData = tariffResp.data.payload;
      const currencyData = currencyResp.data.payload;

      // Perform calculations based on fetched data
      const cf = parseFloat(formValues.Cost) + parseFloat(formValues.Freight);
      const cif = cf + parseFloat(formValues.Insurance);
      const exRate = parseFloat(currencyData.Rate.$numberDecimal);

      // Additional calculations using tariffData
      const duty = cif * (parseFloat(tariffData.DUTY.$numberDecimal) / 100);
      const vat = (cif + duty) * (parseFloat(tariffData.VAT.$numberDecimal) / 100);
      const sur = cif * (parseFloat(tariffData.SUR.$numberDecimal) / 100);
      const etl = cif * (parseFloat(tariffData.ETLS.$numberDecimal) / 100);
      const ciss = cif * (parseFloat(tariffData.CISS.$numberDecimal) / 100);
      const nac = cif * (parseFloat(tariffData.NAC.$numberDecimal) / 100);
      const levy = cif * (parseFloat(tariffData.LEVY.$numberDecimal) / 100);
      const totalPayableLocal = (duty + vat + sur + etl + ciss + nac + levy) * exRate;

      // Update state with calculation results
      setFormValues({
        ...formValues,
        ExRate: exRate,
        CF: cf,
        CIF: cif,
        CIFLocal: (cif * exRate).toFixed(2),
        IDRate: tariffData.$numberDecimal,
        IDPayableLocal: (duty * exRate).toFixed(2),
        VATRate: tariffData.VAT.$numberDecimal,
        VATPayableLocal: (vat * exRate).toFixed(2),
        ETLRate: tariffData.ETLS.$numberDecimal,
        ETLPayableLocal: (etl * exRate).toFixed(2),
        SURRate: tariffData.SUR.$numberDecimal,
        SURPayableLocal: (sur * exRate).toFixed(2),
        CISSRate: tariffData.CISS.$numberDecimal,
        CISSPayableLocal: (ciss * exRate).toFixed(2),
        NACRate: tariffData.NAC.$numberDecimal,
        NACPayableLocal: (nac * exRate).toFixed(2),
        LEVYRate: tariffData.LEVY.$numberDecimal,
        LEVYPayableLocal: (levy * exRate).toFixed(2),
        TotalPayableLocal: totalPayableLocal.toFixed(2),
        HSCodeDescription: tariffData.Description,
        DisplayResult: true,
      });
    } catch (error) {
      setFormValues({ ...formValues, errorMessage: `Error fetching data: ${error.message}` });
    }
  };

  const renderCommodityDetails = () => {
    return (
      <table className="table ui basic teal">
        <tbody>
          <tr>
            <td colSpan="4">
              <h3>COMMODITY DETAILS</h3>
            </td>
          </tr>
          <tr>
            <td>HS Code</td>
            <td>
              <h5>{formValues.HSCode}</h5>
            </td>
            <td>Computed On</td>
            <td>
              <h5>{new Date().toLocaleDateString()}</h5>
            </td>
          </tr>
          <tr>
            <td>HS Code Description</td>
            <td colSpan="3">
              <h5>{formValues.HSCodeDescription}</h5>
            </td>
          </tr>
          <tr>
            <td>Commodity Description</td>
            <td colSpan="3">
              <h5>{formValues.ProductDesc}</h5>
            </td>
          </tr>
        </tbody>
      </table>
    );
  };
  

  const renderCalculationResults = () => {
    return (
      <table className="table ui basic teal">
        <tbody>
          <tr>
            <td colSpan="4">
              <h3>COST, INSURANCE & FREIGHT ({formValues.Currency})</h3>
            </td>
          </tr>
          <tr>
            <td>FOB</td>
            <td>
              <h5>{formValues.Cost}</h5>
            </td>
            <td>Exchange Rate</td>
            <td>
              <h5>{formValues.ExRate}</h5>
            </td>
          </tr>
          <tr>
            <td>Freight</td>
            <td>
              <h5>{formValues.Freight}</h5>
            </td>
            <td>Insurance</td>
            <td>
              <h5>{formValues.Insurance}</h5>
            </td>
          </tr>
          <tr>
            <td>Cost and Freight (CF)</td>
            <td>
              <h5>{formValues.CF}</h5>
            </td>
            <td>Cost Ins and Freight (CIF)</td>
            <td>
              <h5>{formValues.CIF}</h5>
            </td>
          </tr>
          <tr>
            <td colSpan="4" className="">
              <h3>DUTY, TAXES & LEVIES (NGN)</h3>
            </td>
          </tr>
          <tr>
            <td>CIF in Nigeria Naira</td>
            <td>
              <h5>{formValues.CIFLocal}</h5>
            </td>
            <td></td>
            <td></td>
          </tr>
          <tr>
            <td>IMPORT DUTY {formValues.IDRate}%</td>
            <td>
              <h5>{formValues.IDPayableLocal}</h5>
            </td>
            <td>NAC {formValues.NACRate}%</td>
            <td>
              <h5>{formValues.NACPayableLocal}</h5>
            </td>
          </tr>
          <tr>
            <td>VAT {formValues.VATRate}%</td>
            <td>
              <h5>{formValues.VATPayableLocal}</h5>
            </td>
            <td>ETL {formValues.ETLRate}%</td>
            <td>
              <h5>{formValues.ETLPayableLocal}</h5>
            </td>
          </tr>
          <tr>
            <td>SURCHARGE {formValues.SURRate}%</td>
            <td>
              <h5>{formValues.SURPayableLocal}</h5>
            </td>
            <td>CISS {formValues.CISSRate}%</td>
            <td>
              <h5>{formValues.CISSPayableLocal}</h5>
            </td>
          </tr>
          <tr>
            <td>OTHER CHARGES</td>
            <td>N/A</td>
            <td></td>
            <td></td>
          </tr>
          <tr>
            <td colSpan="3" style={{ textAlign: 'right', color: 'red' }}>
              <h3>PROPOSED DUTY AND TAXES PAYABLE</h3>
            </td>
            <td width="25%">
              <h3 style={{ color: 'red' }}>NGN {formValues.TotalPayableLocal}</h3>
            </td>
          </tr>
        </tbody>
      </table>
    );
  };
  

  return (
    <div>
      <Header
        style={{ marginTop: '1em' }}
        textAlign="centered"
        as="h3"
        className="AutumnHomeText"
        content="PRODUCT DUTY CALCULATOR"
        subheader="To use the duty calculator, enter your product details and cost information, then submit the form to calculate import duties, taxes, and other charges."
      />

    <div className="ui grid">
      <div className="five wide column">
        <Segment color="grey">
          <Form onSubmit={handleSubmit}>
            <Form.Field>
              <label>Commodity Description</label>
              <Input
                name="ProductDesc"
                placeholder="Enter Commodity Description"
                value={formValues.ProductDesc}
                onChange={handleInputChange}
              />
            </Form.Field>
            <Form.Field>
              <label>HS Code</label>
              <div className="ui input action">
                <Input
                  name="HSCode"
                  placeholder="Enter HS Code"
                  value={formValues.HSCode}
                  onChange={handleInputChange}
                />
                <Button type="button" className="ui button">
                  Search
                </Button>
              </div>
            </Form.Field>
            <Form.Field>
              <label>Currency</label>
              <Select
                name="Currency"
                options={currencyOptions}
                placeholder="Select Currency"
                value={formValues.Currency}
                onChange={handleSelectChange}
              />
            </Form.Field>
            <Form.Field>
              <label>Cost Price</label>
              <Input
                name="Cost"
                placeholder="Enter Cost Price"
                value={formValues.Cost}
                onChange={handleInputChange}
              />
            </Form.Field>
            <Form.Field>
              <label>Freight Amount</label>
              <Input
                name="Freight"
                placeholder="Enter Freight Amount"
                value={formValues.Freight}
                onChange={handleInputChange}
              />
            </Form.Field>
            <Form.Field>
              <label>Insurance Amount</label>
              <Input
                name="Insurance"
                placeholder="Enter Insurance Amount"
                value={formValues.Insurance}
                onChange={handleInputChange}
              />
            </Form.Field>
            <Button type="submit" className="ui button PrimaryBackground">
              Calculate
            </Button>
          </Form>
        </Segment>
      </div>

      <div className="eleven wide column">
        <div>
          {formValues.DisplayResult && renderCommodityDetails()}
          {formValues.DisplayResult && renderCalculationResults()}
        </div>
        {formValues.errorMessage && (
          <Message negative>
            <Message.Header>Error</Message.Header>
            <p>{formValues.errorMessage}</p>
          </Message>
        )}
      </div>
    </div>

    </div>
  );
};

export default DutyCalculator;






